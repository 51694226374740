const appConfig = {
  servicePrefix: {
    local_dev: {
      auth: "http://localhost:753",
      app: "http://localhost:754",
      district: "https://apiv2.quamuslms.id/district",
    },
    local_prod: {
      auth: "http://localhost:702",
      app: "http://localhost:703",
      district: "https://apiv2.quamuslms.id/district",
    },
    prod: {
      auth: "https://portalapi.quamuslms.id/auth",
      app: "https://portalapi.quamuslms.id/app",
      district: "https://apiv2.quamuslms.id/district",
    },
  },
  authenticatedEntryPath: "/home",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  locale: "en",
  enableMock: false,
  stage: "prod",
};

export default appConfig;
